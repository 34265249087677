.app {
  position: relative;
  margin: 0;
  background-color: #f4f6fc;
  background-size: 200px 200px;
  background-image: linear-gradient(to right, white 1px, transparent 1px),
    linear-gradient(to bottom, white 1px, transparent 1px);
}

.wrapper {
  /* position: absolute;
  top: 0;
  left: 0; */
  position: relative;
  width: 100vw;
}

.blobs {
  z-index: 0;
  /* position: relative; */
  position: absolute;
  top: 0;
  left: 0;
  min-height: 1500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
}

.blobs::before,
.blobs::after {
  content: "";
  width: 70vmax;
  height: 60vmax;
  position: absolute;
  /* background: rgba(0, 0, 0, 0.07); */
  background: radial-gradient(#dadff8, #f4f6fc);
  filter: blur(10px);
  left: -20vmin;
  top: -20vmin;
  -webkit-animation: squish 15s linear infinite alternate,
    spin 20s linear infinite;
  animation: squish 15s linear infinite alternate, spin 20s linear infinite;
  z-index: 1;
  will-change: border-radius, transform;
  transform-origin: 55% 55%;
  pointer-events: none;
}

.blobs::after {
  width: 60vmin;
  height: 50vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom: 0;
  animation: squish 10s linear infinite alternate,
    spin 26s linear infinite reverse;
  transform-origin: 20% 20%;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes squish {
  0% {
    border-radius: 40% 60% 60% 40%/70% 30% 70% 30%;
  }
  100% {
    border-radius: 40% 60%;
  }
}
@keyframes squish {
  0% {
    border-radius: 40% 60% 60% 40%/70% 30% 70% 30%;
  }
  100% {
    border-radius: 40% 60%;
  }
}

.container {
  width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
  color: #3a2f64;
}

.header {
  padding: 30px 0;
  display: grid;
  grid-template-columns: auto 200px;
}

.logo {
  width: 215px;
}

.button-primary {
  padding: 10px 20px;
  height: 60px;
  width: 200px;
  background-color: #5d6bf2;
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  margin: auto;
  cursor: pointer;
}

.button-secondary {
  box-sizing: border-box;
  padding: 10px 20px;
  height: 60px;
  width: 200px;
  border: 2px solid #3a2f64;
  color: #3a2f64;
  border-radius: 6px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  margin: auto;
  cursor: pointer;
}

.hero {
  text-align: center;
  margin: 130px auto;
}

.hero-text {
  font-size: 76px;
  font-weight: bold;
  line-height: 80px;
}

.hero-text > div {
  display: inline-block;
  color: #13b7fd;
}

.hero-subtext {
  font-size: 24px;
  font-weight: medium;
  margin: 40px auto;
}

.hero-subtext > div {
  display: inline-block;
  color: #5d6bf2;
}

.stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 22px;
}

.stats-cell {
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 147px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 8px 16px rgba(49, 44, 73, 0.1);
  border-radius: 15px;
}

.stats-cell-text {
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 40px;
  text-align: center;
  color: #13b7fd;
}

.stats-cell-subtext {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.features {
  margin-top: 100px;
  margin-bottom: 60px;
}

.section-surtitle {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #13b7fd;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.section-title {
  font-weight: bold;
  font-size: 54px;
  text-align: center;
}

.section-subtitle {
  font-weight: 400;
  font-size: 24px;
  color: #8c86a4;
  text-align: center;
}

.features-first-row {
  margin-top: 60px;
}

.features-second-row {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.card {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 8px 16px rgba(49, 44, 73, 0.1);
  border-radius: 24px;
  height: 400px;
  position: relative;
}

.center {
  text-align: center;
}

.feature-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #3a2f64;
  margin-bottom: 20px;
}

.feature-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8c86a4;
}

.bg-for-users {
  background: url(../public/bg-for-users.svg) no-repeat, #ffffff;
  background-size: contain;
  background-position: right center;
}

.container-for-users {
  position: absolute;
  top: 60px;
  left: 40px;
  width: 40%;
  padding: 40px;
}

.bg-build {
  background: url(../public/bg-build.svg) no-repeat, #ffffff;
  background-size: cover;
  background-position: center;
}

.container-build {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
}

.bg-validate {
  background: url(../public/bg-validate.svg) no-repeat, #ffffff;
  background-size: cover;
  background-position: center;
}

.container-validate {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
}

.section-comments {
  margin: 200px auto;
  position: relative;
}

.section-comments .card {
  height: 100%;
  padding: 40px;
}

.comment-photo img {
  width: 70px;
  height: 70px;
  margin: -75px auto 40px auto;
  /* border: 5px solid #ffffff; */
  border-radius: 50%;
}

.comment-content {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #3a2f64;
  margin-bottom: 20px;
}

.comment-author {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #4f45e4;
  margin-bottom: 40px;
}

.section-integartions {
  margin: 200px auto;
  position: relative;
}

.integrations-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
  margin-top: 40px;
}

.integrations-cell img {
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.integration-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #3a2f64;
  margin-bottom: 20px;
}

.integration-content {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #312c49;
  text-align: justify;
  text-justify: inter-word;
}

.section-benefits {
  margin: 200px auto;
  position: relative;
}

.benefits-grid {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.benefit-cell {
  padding: 60px;
  height: auto;
  text-align: left;
}

.benefit-cell img {
  display: block;
  margin-bottom: 20px;
}

.benefit-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  color: #3a2f64;
  margin-bottom: 15px;
}

.benefit-content {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #312c49;
}

.everywhere-buttons {
  margin: 60px auto;
  display: grid;
  grid-template-columns: repeat(4, 140px);
  grid-gap: 20px;
  width: 620px;
}

.button-active {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  color: #5d6bf2;
  background: #ffffff;
  border: 3px solid #5d6bf2;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 8px 16px rgba(49, 44, 73, 0.1);
  border-radius: 10px;
  box-sizing: content-box;
}

.button-inactive {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: normal;
  color: #5d6bf2;
  background: #ffffff;
  border: 2px solid #ebedf2;
  border-radius: 10px;
  box-sizing: content-box;
}

.everywhere-cell {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
}

.everywhere-cell img {
  height: 400px;
}

.everywhere-cell-content {
  padding: 60px;
}

.everywhere-cell-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #3a2f64;
}

.everywhere-cell-text {
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.everywhere-cell-arrow {
  position: absolute;
  bottom: 60px;
  right: 60px;
}

.everywhere-cell-arrow div {
  line-height: 24px;
  display: inline-block;
}

.everywhere-cell-arrow img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: 12px;
  vertical-align: text-bottom;
}

.section-newsletter {
  margin: 200px auto;
  position: relative;
}

.newsletter-form {
  display: grid;
  grid-template-columns: 600px 200px;
  grid-gap: 20px;
  margin-top: 60px;
  width: 820px;
  margin: 60px auto;
}

.newsletter-form input {
  padding: 10px 20px;
  height: 60px;
  width: 600px;
  background-color: #fff;
  border-radius: 6px;
  border: 2px solid #4f45e4;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  margin: auto;
}

.footer {
  background-color: #fff;
  margin: 200px auto;
  padding: 60px;
}

.footer-container {
  width: 1280px;
  margin: 0 auto;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 100px;
}

.footer-links-section {
  margin-bottom: 40px;
}

.footer-links-section div {
  display: block;
  font-weight: bold;
  line-height: 50px;
}

.footer-links-section a {
  display: block;
  color: #3a2f64;
  text-decoration: none;
  line-height: 30px;
}

.footer-terms {
  border-top: 1px solid #C7D5EE;
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

}


.footer-terms-right {
}

.footer-terms-right a {
  float: right;
  margin-left: 20px;
  display: block;
  color: #3a2f64;
  text-decoration: none;
  line-height: 30px;
}